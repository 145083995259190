

.contact-page .contact-page-header .contact-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 242px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 15px;
    background-color: #ededed;
}

.contact-page .contact-page-header .contact-info i{
    font-size: 64px;
    color: #000;
}

.contact-page .contact-page-header .contact-info h2{
    font-size: 17px;
    margin-top: 20px;
    font-size: 16px;
    color: #000;
}

.contact-page .contact-page-header .contact-info h1{
    font-size: 17px;
    color: #656565;
    font-weight: 400;
    margin-top: 20px;
}

.contact-page .contact-page-content{
    margin-top: 40px;
    margin-bottom: 40px;
}




.contact-page .contact-page-content .form h1{
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 50px;
    color: #000;
    font-weight: 900;
}

.contact-page .contact-page-content .form h1 span{
    color: #494949;
}

.contact-page .contact-page-content .form h1 p{
    font-size: 19px;
    font-weight: 400;
    color: #7a7a7a;
    margin-bottom: 58px;
}






.contact-page .contact-page-content .form{
    min-height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    padding: 40px;
    align-items: flex-start;
}


.contact-page .contact-page-content .form form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 500px;
}

.contact-page .contact-page-content .form form .inputs{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.contact-page .contact-page-content .form form .inputs .input{
    width: 100%;
    margin: 9px;
    position: relative;
}

.contact-page .contact-page-content .form form .inputs .input i{
    position: absolute;
    top: 22px;
    right: 10px;
    font-size: 23px;
    color: #929292;
}

.contact-page .contact-page-content .form form .inputs .input input{
    width: 100%;
    border: none;
    outline: none;
    background-color: #fff;
    height: 47px;
    margin: 7px;
    padding: 25px;
}

.contact-page .contact-page-content .form form .inputs .input select{
    width: 100%;
    border: none;
    outline: none;
    background-color: #fff;
    height: 47px;
    margin: 7px;
    padding: 25px;
    color: #000;
}

.contact-page .contact-page-content .form form .inputs .input textarea{
    width: 100%;
    border: none;
    outline: none;
    background-color: #fff;
    height: 200px;
    margin: 7px;
    padding: 25px;
}

.contact-page .contact-page-content .form form button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    background-color: #000;
    color: #fff;
    gap: 15px;
    font-size: 15px;
    border: none;
    outline: none;
    margin-top: 40px;
}

@media(max-width:950px){
    .contact-page .contact-page-content .form form .inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .page-header .page-header-content .text h1 {
        font-size: 91px;
        font-weight: 900;
        color: #fff;
        text-align: center;
    }
}