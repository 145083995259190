.upper-nav{
    background-color: #000;
    padding-top: 7px;
    padding-bottom: 7px;
}

.upper-nav .upper-nav-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
    min-height: 35px;
}



.upper-nav .upper-nav-content .contact-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}


.upper-nav .upper-nav-content .contact-info i{
    font-size: 19px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    color: #fff;
    background-color: #ffffff5e;
    border-radius: 50%;
    padding: 7px;
}

.upper-nav .upper-nav-content .contact-info .text a{
    color: #fff;
    font-weight: 300;
    font-size: 14px;
}

.upper-nav .upper-nav-content h3{
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: #fff;
}

.upper-nav .upper-nav-content ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding: 0;
}


.upper-nav .upper-nav-content ul li a{
    color: #fff;
}


@media(max-width:950px){
    .upper-nav .upper-nav-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 17px;
        min-height: 35px;
        justify-content: center;
        margin-top: 15px;
    }
}