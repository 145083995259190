.landing{

}


.landing .landing-content{
    min-height: 650px;
    position: relative;
}


.landing .landing-content .overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000078;
    z-index: 1;
}

.landing .landing-content img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.landing .landing-content .text{
    position: relative;
    z-index: 3;
    min-height: 650px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    width: 73%;
    padding-left: 30px;
}


.landing .landing-content .text h2{
    font-size: 18px;
    color: #fff;
}

.landing .landing-content .text h1{
    font-size: 58px;
    color: #fff;
    font-weight: 700;
}


.landing .landing-content .text p{
    font-size: 17px;
    width: 72%;
    color: #fff;
    font-weight: 400;
}


.landing .landing-content .text a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    background-color: #000;
    color: #fff;
    gap: 15px;
    font-size: 16px;
}

@media(max-width:950px){
    .landing .landing-content .text h2 {
        font-size: 18px;
        color: #fff;
        margin-top: 26px;
    }

    .landing .landing-content .text {
        position: relative;
        z-index: 3;
        min-height: 650px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 30px;
        width: 100%;
        padding-left: 30px;
    }

    .landing .landing-content .text p {
        font-size: 17px;
        width: 81%;
        color: #fff;
        font-weight: 400;
    }

    .landing .landing-content .text h1 {
        font-size: 33px;
        color: #fff;
        font-weight: 700;
    }
    
}