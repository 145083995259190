
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');


body{
  background-color: #fff !important;
  scroll-behavior: smooth;

}

*{
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: 'Roboto', sans-serif;
  
}

/* font-family: 'IBM Plex Sans Arabic', sans-serif; */

a{
  text-decoration: none !important;
}

ul{
  list-style: none !important;
  margin: 0 !important;
}


.hide{
  display: none !important;
}

.facebook{
  color: #0572e6 !important;

}

.twitter{
  color: #1d9bf0 !important;
}

.instagram{
  color: #c6346b !important;
}

.tiktok{
  color: #fe2c55 !important;
}


.whatsapp{
  color: #0dc143 !important;
}



.loader {
  position: relative;
}

.outer,
.middle,
.inner {
  border: 3px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.outer {
  width: 3.5em;
  height: 3.5em;
  margin-left: -1.75em;
  margin-top: -1.75em;
  animation: spin 2s linear infinite;
}

.middle {
  width: 2.1em;
  height: 2.1em;
  margin-left: -1.05em;
  margin-top: -1.05em;
  animation: spin 1.75s linear reverse infinite;
}

.inner {
  width: 0.8em;
  height: 0.8em;
  margin-left: -0.4em;
  margin-top: -0.4em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

