nav{
    overflow: hidden;
}



nav .nav-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
}

nav .nav-content .logo{
    background-color: #000;
    width: 22%;
    border-radius: 71px;
    margin-left: -80px;
}

nav .nav-content .logo img{
    width: 130px;
    height: 130px;
    object-fit: contain;
    margin-left: 69px;
}


nav .nav-content .nav-elements{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 75%;
}

nav .nav-content .nav-elements ul{
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 31px;
    margin-left: 34px !important;
}


nav .nav-content .nav-elements ul li a{
    color: #000;
    font-size: 17px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

nav .nav-content .nav-elements ul li a i{
    font-size: 17px;
    color: #949494;
}


nav .nav-content .nav-elements>a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    background-color: #000;
    color: #fff;
    gap: 15px;
    font-size: 20px;
}






#open-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
    color: #000;
}

#close-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
}


@media(max-width:950px){

    
    #open-nav{
        display: block;
    }

    #close-nav{
        display: block;
    }

    nav .nav-content .nav-elements {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        height: 100%;
        width: 100%;
        justify-content: flex-start;
        z-index: 15015;
        padding-top: 80px;
        overflow-y: scroll;
    }

    nav .nav-content .nav-elements ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 0;
        justify-content: center;
        align-items: center;
    }

    
    nav .nav-content .nav-elements{
        display: none;
    }

    nav .nav-content .nav-elements ul li a {
        color: #000;
        font-size: 17px;
        font-weight: 500;
    }

    nav .nav-content .nav-elements ul li .inner-eles {
        align-items: flex-start;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 4px rgba(0,0,0,.42);
        display: flex;
        flex-direction: column;
        gap: 15px;
        left: 0;
        min-height: 150px;
        opacity: 0;
        padding: 7px;
        pointer-events: none;
        position: absolute;
        top: -196px;
        transition: .3s;
        width: 216px;
        z-index: 2000;
    }

    nav .nav-content .nav-elements ul .inner-eles.active{
        opacity: 1;
        pointer-events: inherit;
        position: relative;
        top: 0;
        left: 0;
    }

    nav .nav-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 17px;
        justify-content: space-between;
    }

    nav .nav-content .logo {
        background-color: #000;
        width: 80%;
        border-radius: 71px;
        margin-left: -80px;
    }
    

}


