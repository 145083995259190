.about{
    margin-top: 82px;
    margin-bottom: 80px;
}

.about .about-images{
    position: relative;
    min-height: 600px;
}

.about .about-images .large-img{
    width: 60%;
    height: 500px;
    object-fit: cover;
    position: relative;
    left: 10%;
}

.about .about-images .small-img{
    width: 350px;
    height: 250px;
    object-fit: cover;
    position: absolute;
    top: 20px;
    left: 66%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.36);
    border: 4px solid #fff;
}

.about .about-images .number{
    min-width: 150px;
    height: 150px;
    background-color: #fff;
    position: absolute;
    bottom: 62px;
    right: 101px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 23px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.36);
}


.about .about-images .number h3{
    font-size: 15px;
}


.about .about-images .number h4{
    font-size: 65px;
    font-weight: bold;
    color: #808080;
}


.about .about-text h2{
    font-size: 15px;
    color: #6d6d6d;
}

.about .about-text h1{
    margin-top: 15px;
    margin-bottom: 33px;
    font-weight: 800;
    color: #000;
}

.about .about-text p{
    font-size: 15px;
    color: #7e7e7e;
}

.about .about-text .benefits{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 45px;
    margin-bottom: 64px;
}

.about .about-text .benefits img{
    width: 100px;
    height: 133px;
    object-fit: cover;
}


.about .about-text .benefits ul{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.about .about-text .benefits ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    font-size: 15px;
}


.about .about-text .benefits ul li i{
    color: #8c8c8c;
}

.about .about-text a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    background-color: #000;
    color: #fff;
    gap: 15px;
    font-size: 15px;
}


@media(max-width:950px){
    .about .about-images .large-img {
        width: 80%;
        height: 500px;
        object-fit: cover;
        position: relative;
        left: 10%;
    }

    .about .about-images .small-img {
        width: 247px;
        height: 182px;
        object-fit: cover;
        position: absolute;
        top: 20px;
        left: 66%;
        transform: translateX(-50%);
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.36);
        border: 4px solid #fff;
    }
}