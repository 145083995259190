.services{
    margin-top: 70px;
    margin-bottom: 70px;
}

.services .services-content{
    position: relative;
    min-height: 700px;
    padding-top: 26px;
    padding-bottom: 27px;
}

.services .services-content>img{
    width: 50%;
    position: absolute;
    right: 0;
    object-fit: cover;
    height: 62%;
}

.services .services-content .services-header{
    margin-top: 64px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px;
    position: relative;
}


.services .services-content .services-header .text h2{
    font-size: 16px;
    color: #868686;
}

.services .services-content .services-header .text h1{
    font-size: 50px;
    font-weight: 900;
    color: #000;
    width: 80%;
}

.services .services-content .services-header .buttons{
    display: flex;
    flex-direction: row;
    gap: 18px;
}

.services .services-content .services-header .buttons button{
    width: 50px;
    height: 50px;
    border: none;
    outline: none;
    background-color: #fff;
    font-size: 28px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.36);
}


.services .services-content .services-slider .service{
    width: 350px;
    margin: 20px;
    background-color: #fff;
    padding: 20px;
    min-height: 562px;
    margin-bottom: 66px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.36);
}


.services .services-content .services-slider .swiper-slide{
    width: fit-content;
}

.services .services-content .services-slider .service img{
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.services .services-content .services-slider .service h1{
    margin-top: 18px;
    margin-bottom: 19px;
    font-size: 21px;
    font-weight: 800;
    color: #000;
}

.services .services-content .services-slider .service p{
    font-size: 15px;
    color: #767676;
}

.services .services-content .services-slider .service a{
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

@media(max-width:950px){
    .services .services-content .services-header .text h1 {
        font-size: 26px;
        font-weight: 900;
        color: #000;
        width: 80%;
    }

    .services .services-content .services-slider .service {
        width: 100%;
        margin: 0; 
        background-color: #fff;
        padding: 20px;
        min-height: 562px;
        margin-bottom: 66px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.36);
    }
}