.page-header{
    min-height: 451px;
    position: relative;
}

.page-header .overlay{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #00000087;
}

.page-header img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.page-header .page-header-content{
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
}



.page-header .page-header-content .text h1{
    font-size: 91px;
    font-weight: 900;
    color: #fff;
}

.page-header .page-header-content .text .links{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.page-header .page-header-content .text .links a{
    font-size: 18px;
    color: #fff;
}

.page-header .page-header-content .text .links i{
    color: #fff;
    font-size: 19px;
}


.about-page{
    margin-bottom: 80px;
    margin-top: 40px;
}



.about-page .number{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
}


.about-page .number .icon{
    width: 70px;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #000;
    border-radius: 50%;
    font-size: 36px;
    color: #fff;
    box-shadow: 0px 6px 0px rgba(0, 0, 0, 0.31);
}


.about-page .number .dot{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 6px solid #000;
    border-radius: 50%;
    outline: 5px solid #b5b5b5;
    margin-top: 20px;
    margin-bottom: 30px;
}


.about-page .number h1{
    font-size: 51px;
    font-weight: 900;
    color: #000;
}

.about-page .number h2{
    font-size: 14px;
    color: #8b8b8b;
}