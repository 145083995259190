footer{
    background-color: #000;
    padding-top: 50px;
    padding-bottom: 50px;
}

footer .footer-content{
    margin-bottom: 34px;
}



footer .footer-content .logo img{
    width: 151px;
    height: 150px;
    object-fit: contain;
}

footer .footer-content p{
    margin-top: 17px;
    margin-bottom: 20px;
    font-size: 16px;
    color: #c2c2c2;
    font-weight: 300;
}


footer .footer-content h2{
    font-size: 17px;
    font-weight: 800;
    color: #fff;
    margin-top: 30px;
}


footer .footer-content h3{
    font-size: 16px;
    color: #bcbcbc;
    font-weight: 200;
}


footer .footer-content .social{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

footer .footer-content .social a{
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #2f2f2f;
    font-size: 27px;
}

footer .footer-content h1{
    font-size: 18px;
    font-weight: 800;
    color: #fff;
}

footer .footer-content .line{
    
    width: 23%;
    display: flex;
    height: 2px;
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
}

footer .footer-content .line span{
    position: absolute;
    height: 2px;
    width: 7px;
    border-radius: 50%;
    background-color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 17px;
    left: 16px;
}

footer .footer-content ul{
    display: flex;
    flex-direction: column;
    gap: 26px;
}

footer .footer-content ul li{
    font-size: 17px;
    color: #fff;
}

footer .footer-content ul li a{
    color: #c9c9c9;
    font-weight: 300;
}


footer .footer-content .contact-info .info{
    margin-top: 33px;
}

footer .footer-content .contact-info .info h4{
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}

footer .footer-content .contact-info .info a{
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    font-size: 15px;
    color: #ffffff8c;
}

footer .footer-content .contact-info .info a i{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #ffffff54;
    color: #fff;
}

footer .footer-content>a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    background-color: #fff;
    color: #000;
    gap: 15px;
    font-size: 15px;
    border: none;
    outline: none;
    margin-top: 40px;
    margin-top: 127px;
}

footer .copy-right{
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

footer .copy-right h1{
    font-size: 17px;
    color: #fff;
}

footer .copy-right h1 span{
    color: #656565;
}