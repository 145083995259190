.contact{
    margin-top: 100px;
}



.contact .contact-text h1{
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 50px;
    color: #000;
    font-weight: 900;
}

.contact .contact-text h1 span{
    color: #494949;
}

.contact .contact-text p{
    font-size: 19px;
    font-weight: 400;
    color: #7a7a7a;
    margin-bottom: 58px;
}

.contact .contact-text img{
    width: 100%;
    height: 527px;
    object-fit: cover;
}

.contact .contact-form{
    min-height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3
}

.contact .contact-form form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 500px;
    padding: 20px;
}

.contact .contact-form form .inputs{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.contact .contact-form form .inputs .input{
    width: 100%;
    margin: 9px;
    position: relative;
}

.contact .contact-form form .inputs .input i{
    position: absolute;
    top: 22px;
    right: 10px;
    font-size: 23px;
    color: #929292;
}

.contact .contact-form form .inputs .input input{
    width: 100%;
    border: none;
    outline: none;
    background-color: #fff;
    height: 47px;
    margin: 7px;
    padding: 25px;
}

.contact .contact-form form .inputs .input select{
    width: 100%;
    border: none;
    outline: none;
    background-color: #fff;
    height: 47px;
    margin: 7px;
    padding: 25px;
    color: #000;
}

.contact .contact-form form .inputs .input textarea{
    width: 100%;
    border: none;
    outline: none;
    background-color: #fff;
    height: 200px;
    margin: 7px;
    padding: 25px;
}

.contact .contact-form form button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    background-color: #000;
    color: #fff;
    gap: 15px;
    font-size: 15px;
    border: none;
    outline: none;
    margin-top: 40px;
}


@media(max-width:950px){
    .contact .contact-form form .inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}